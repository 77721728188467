//------------------------------------------------------------------------------------------------
// Animated corners
//------------------------------------------------------------------------------------------------

@keyframes cornersAppear
{
    0%     { height: 50%;  width: 50%; border-color: black; }
    59%    { height: 4px;  width: 4px; border-color: white; }
    60%    { border-color: black; }
    80%    { border-color: white; }
    100%   { border-color: black; }
}

@keyframes cornersAppearAndDisappear
{
    0%     { height: 50%;  width: 50%; border-color: transparent; }
    14%    { height: 4px;  width: 4px; border-color: white; }
    15%    { border-color: transparent; }
    20%    { border-color: white; }
    24%    { border-color: transparent; }
    25%    { border-color: white; }
    100%   { border-color: transparent; }
}

.cornerAppear {
    animation-name: cornersAppear;
    animation-duration: 0.6s;
    border: 0px solid var(--color-border-5);
}

.cornerAppearAndDisappear {
    animation-name: cornersAppearAndDisappear;
    animation-duration: 2.4s;
    border: 0px solid transparent;
}

.cornerTopLeft,
.cornerTopRight,
.cornerBottomLeft,
.cornerBottomRight {
    position: absolute;
    z-index: 1000;
    width: 5px;
    height: 5px;

}    

.cornerTopLeft {
    top: 0;
    left: 0;
    border-top-width: 1px;
    border-left-width: 1px;
}

.cornerTopRight {
    top: 0;
    right: 0;
    border-top-width: 1px;
    border-right-width: 1px;
}
    
.cornerBottomLeft {
    bottom: 0;
    left: 0;
    border-bottom-width: 1px;
    border-left-width: 1px;
}

.cornerBottomRight {
    bottom: 0;
    right: 0;
    border-bottom-width: 1px;
    border-right-width: 1px;
}
