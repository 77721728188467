.layout {
    display: flex;
    flex-wrap: wrap;
}

.layout.horizontal {
    flex-direction: row;
}

.layout.horizontal.xleft {
    justify-content: flex-start;
}

.layout.horizontal.xcenter {
    justify-content: center;
}

.layout.horizontal.xfill {
    justify-content: space-between;
}

.layout.horizontal.xdispatch {
    justify-content: space-around;
}

.layout.horizontal.xright {
    justify-content: flex-end;
}

.layout.horizontal.ytop {
    align-items: flex-start;
}

.layout.horizontal.ycenter {
    align-items: center;
}

.layout.horizontal.ybottom {
    align-items: flex-end;
}

.layout.horizontal.scrollable {
    overflow-x: auto;
}

.layout.vertical {
    flex-direction: column;
}

.layout.vertical.ytop {
    justify-content: flex-start;
}

.layout.vertical.ycenter {
    justify-content: center;
}

.layout.vertical.ydispatch {
    justify-content: space-around;
}

.layout.vertical.yfill {
    justify-content: space-between;
}

.layout.vertical.ybottom {
    justify-content: flex-end;
}

.layout.vertical.xleft {
    align-items: flex-start;
}

.layout.vertical.xcenter {
    align-items: center;
}

.layout.vertical.xright {
    align-items: flex-end;
}

.layout.vertical.scrollable {
    overflow-y: auto;
}

.layout.scrollable {
    flex-wrap: nowrap;
}

.main {
    flex: 1;
}

.padded {
    padding: var(--spacing-medium);
}

.gapped {
    gap: var(--spacing-medium);
}

.tested {
    border: var(--border-small) dotted orangered;
}
