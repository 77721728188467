html,
body {
    height: 100%;
    margin: 0;
    background-color: var(--color-background-0);
    color: var(--color-text-0);
    font-family: Arial, Helvetica, sans-serif;

    /* disable blue-highlighting on tap on mobile */
    -webkit-tap-highlight-color: transparent;
}

td {
    padding: var(--spacing-medium);
}

button {
    border: none;
    padding: var(--spacing-medium);
}

table {
    font-size: small;
    border-collapse: collapse;
}

tr:hover {
    cursor: pointer;
    background-color: var(--color-background-1);
}

/*@media (max-width: 1000px) {

    table {
        font-size: xx-small;
    }
}*/
