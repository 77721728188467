body {
    --color-background-0: #13181D;
    --color-background-1: #181d20;
    --color-background-2: #151a1f;
    --color-background-3: #EEEEEE; /* inputs */
    --color-background-4: tan;
    --color-background-5: #ffffff;
    --color-background-6: steelblue;
    --color-border-0: #111f11;
    --color-border-1: #13181D;
    --color-border-3: #8f8c88;
    --color-border-5: #ffffff;
    --color-text-0: #ffffff;
    --color-text-1: tan;
    --color-text-2: #000f08;
    --color-text-3: #8f8c88;

    --color-valid: greenyellow;
    --color-warning: orange;
    --color-error: crimson;
    --color-simulation: #389fc2;

    --color-accounts-status-unconfirmed: white;
    --color-accounts-status-active: greenyellow;

    --color-operators-status-iddle: white;
    --color-operators-status-active: greenyellow;
    --color-operators-status-classified: crimson;

    --color-store-orders-status-canceled: grey;
    --color-store-orders-status-unpaid: lightGrey;
    --color-store-orders-status-paymentInProgress: yellow;
    --color-store-orders-status-paymentFailed: crimson;
    --color-store-orders-status-paid: greenyellow;
    --color-store-orders-status-readyForShipping: royalBlue;
    --color-store-orders-status-shipped: cyan;
    --color-store-orders-status-complete: white;
    --color-store-orders-status-payOnDelivery: violet;
}
