@import url('./styles-variables.scss');
@import url('./styles-layout.scss');
@import url('./styles-overrides.scss');


.active {
    color: var(--color-text-1);
    animation: pulsing 2s;
    animation-iteration-count: infinite;
}

.valid {
    color: var(--color-valid) !important;
}

.invalid,
.error {
    color: var(--color-error) !important;
}

.warning {
    color: var(--color-warning) !important;
}

.highlighted {
    background-color: rgba(#444444, 0.8);
}
