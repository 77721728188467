:root {
    /* Spacings: Usable for margins, paddings, gaps */
    --spacing-xsmall: 1px;
    --spacing-small: 2px;
    --spacing-medium: 10px;
    --spacing-large: 20px;
    --spacing-xlarge: 40px;
    --spacing-xxlarge: 100px;

    /* Sizes: Usable for bars and buttons */
    --size-small: 20px;
    --size-medium: 40px;
    --size-large: 80px;
    --size-xlarge: 100px;
    --size-xxlarge: 200px;
    --size-xxxlarge: 300px;

    /* Radius: Usable for any elements  */
    --radius-xsmall: 2px;
    --radius-small: 4px;
    --radius-medium: 8px;
    --radius-large: 14px;
    --radius-xlarge: 20px;
    --radius-max: 50%;

    /* Borders usable for any elements  */
    --border-small: 1px;
    --border-medium: 2px;
    --border-large: 3px;

    /*Opacity: usable for any elements*/
    --opacity-small: 0.2;
    --opacity-medium: 0.5;
    --opacity-large: 0.7;

    /* Regular couples of colors usable for notifications */
    --color-background-success: #3CB371;
    --color-text-success: #222222;
    --color-background-warning: #FFA500;
    --color-text-warning: #222222;
    --color-background-error: #DC143C;
    --color-text-error: #FFFFFF;
    --color-background-action: #FFFFFF;
    --color-text-action: #222222;
    --color-background-cancel: #222222;
    --color-text-cancel: #FFFFFF;
}

body {

}

body.darkTheme {

}
