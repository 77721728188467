@import 'styles-animations.scss';
@import 'styles-tactical-corners.scss';

::-webkit-scrollbar {
    background-color: transparent;
    width: 2px;  // 2px width because 1px may be hidden by the border of the window.
    height: 0px; // 0px for horizontal scrollback (hidden)
}

::-webkit-scrollbar-thumb {
    border-left: 1px solid var(--color-border-5);
}

::-webkit-scrollbar-button {
    background-color: transparent;
}
