.rotate {
    -webkit-animation: rotateAnimation 1s linear infinite both;
    animation: rotateAnimation 1s linear infinite both;
}

@keyframes rotateAnimation {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}



.pulsing {
    animation: pulseAnimation 0.8s;
    animation-iteration-count: infinite;
}

.pulsing-slow {
    animation: pulseAnimation 3s;
    animation-iteration-count: infinite;
}

.pulsing-fast {
    animation: pulseAnimation 0.2s;
    animation-iteration-count: infinite;
}

@keyframes pulseAnimation {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.3;
    }

    100% {
        opacity: 1;
    }
}



.appearing {
    animation-duration: 1s;
    animation-name: appear;
}

@keyframes appear {
    from {
        opacity: 0;
    }

    to {
        opacity: initial;
    }
}



.disappearing {
    animation-duration: 1s;
    animation-name: disappear;
}

@keyframes disappear {
    from {
        opacity: initial;
    }

    to {
        opacity: 0;
    }
}
